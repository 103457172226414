import Link from "next/link";
import { twMerge } from "tailwind-merge";

const baseStyles = {
	blue: "text-aquamarine-blue-900 hover:text-aquamarine-blue-800",
	black: "text-black underline underline-offset-4 hover:text-blue-700",
};

const SiteLink = ({ href, color = "blue", children, className, ...props }) => {
	return (
		<Link
			href={href}
			className={twMerge(
				baseStyles[color],
				"text-base hover:underline underline-offset-3",
				className
			)}
			{...props}
		>
			{children}
		</Link>
	);
};

export default SiteLink;
