import { twMerge } from "tailwind-merge";

export const Header1 = ({ children, className, ...props }) => {
	return (
		<h1
			className={twMerge(
				"font-semibold font-heading text-4xl md:text-5xl md:leading-snug",
				className
			)}
			{...props}
		>
			{children}
		</h1>
	);
};

export default Header1;
