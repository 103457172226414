import { twMerge } from "tailwind-merge";

export const Header3 = ({ children, className, ...props }) => {
	return (
		<h3
			className={twMerge(
				"font-semibold font-heading text-xl md:text-2xl md:leading-5",
				className
			)}
			{...props}
		>
			{children}
		</h3>
	);
};

export default Header3;
