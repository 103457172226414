import { twMerge } from "tailwind-merge";

export const Header2 = ({ children, className, ...props }) => {
	return (
		<h2
			className={twMerge(
				"font-bold font-heading text-3xl md:text-4xl md:leading-tight",
				className
			)}
			{...props}
		>
			{children}
		</h2>
	);
};

export default Header2;
