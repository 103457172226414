import { sanitizeString } from "@/src/sanity/utils/sanitizeString";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
	PlyrLayout,
	plyrLayoutIcons,
} from "@vidstack/react/player/layouts/plyr";
import "@vidstack/react/player/styles/base.css";
import "@vidstack/react/player/styles/plyr/theme.css";

const MuxVideoPlayer = ({ playbackId, thumbnailTime = 15, title = "" }) => {
	return (
		<MediaPlayer
			key={playbackId} // force re-render when playbackId changes
			title={title}
			src={sanitizeString(
				`https://stream.mux.com/${playbackId}.m3u8?default_subtitles_lang=en`
			)}
			className="border-none  aspect-video "
			playsInline
			poster={sanitizeString(
				`https://image.mux.com/${playbackId}/thumbnail.png?time=${thumbnailTime}`
			)}
		>
			<MediaProvider />
			<PlyrLayout
				thumbnails={sanitizeString(
					`https://image.mux.com/${playbackId}/storyboard.vtt`
				)}
				icons={plyrLayoutIcons}
			/>
		</MediaPlayer>
	);
};

export default MuxVideoPlayer;
