import Image from "next/image";
import Link from "next/link";
import { twMerge } from "tailwind-merge";

const FeaturedProducts = ({ value }) => {
	const { products, style = "cards" } = value;
	if (!products || products.length == 0) {
		return <></>;
	}

	switch (style) {
		case "cards":
			return (
				<div
					className={twMerge(
						"w-full py-6 justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-2",
						products.length == 1
							? "md:grid-cols-1 lg:grid-cols-1"
							: products.length == 2
								? "md:grid-cols-2 lg:grid-cols-2"
								: "md:grid-cols-2 lg:grid-cols-3"
					)}
				>
					{products.map((product) => {
						const { store: productData } = product;
						return (
							<Link
								key={productData.id}
								href={`/products/${productData?.slug?.current}`}
								className="group relative flex max-w-sm items-center flex-col flex-grow w-full h-full  py-4 px-4 bg-white  border border-gray-200 rounded-2xl  text-sm text-gray-900 "
							>
								<div className="flex flex-col w-full">
									<div className="relative w-full h-48 flex items-center justify-center">
										<Image
											src={productData.previewImageUrl}
											alt={productData.title}
											className="rounded-lg"
											fill
											sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw"
											style={{ objectFit: "contain" }}
										/>
									</div>
									<div className="flex flex-row w-full justify-center mt-4">
										<p className="text-center inline-block mt-1 text-base  font-promo  leading-tight text-black group-hover:underline underline-offset-2">
											{productData.title}
										</p>
									</div>
								</div>
							</Link>
						);
					})}
				</div>
			);
		case "links":
			return (
				<ul className="flex flex-col gap-y-2 list-disc list-inside ">
					{products.map((product) => {
						const { store: productData } = product;
						return (
							<li key={productData.id}>
								<Link
									href={`/products/${productData?.slug?.current}`}
									className="text-blue-600 font-semibold hover:underline underline-offset-2"
								>
									{productData.title}
								</Link>
							</li>
						);
					})}
				</ul>
			);
		case "compact":
			return (
				<div
					className={twMerge(
						"w-full py-6 justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-2",
						products.length == 1
							? "md:grid-cols-1 lg:grid-cols-1"
							: products.length == 2
								? "md:grid-cols-2 lg:grid-cols-2"
								: "md:grid-cols-2 lg:grid-cols-3"
					)}
				>
					{products.map((product) => {
						const { store: productData } = product;
						return (
							<Link
								key={productData.id}
								href={`/products/${productData?.slug?.current}`}
								className="group relative grid grid-cols-2 max-w-sm    py-2 px-2 bg-white  border border-gray-200 rounded-lg  text-sm text-gray-900 "
							>
								<div className="relative grid-cols-1">
									<Image
										src={productData.previewImageUrl}
										alt={productData.title}
										className="rounded-md"
										width={250}
										height={250}
									/>
								</div>

								<div className="flex flex-row w-full justify-center mt-4">
									<p className="text-center inline-block mt-1 text-base font-semibold font-promo  leading-tight text-black group-hover:underline underline-offset-2">
										{productData.title}
									</p>
								</div>
							</Link>
						);
					})}
				</div>
			);
	}
};

export default FeaturedProducts;
