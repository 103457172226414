import Header1 from "@/components/common/Header1";
import { default as imageUrlBuilder } from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import Image from "next/image";
import Header2 from "@/components/common/Header2";
import Header3 from "@/components/common/Header3";
import Header4 from "@/components/common/Header4";

import { dataset, projectId } from "@/sanity/env";
import MuxVideoPlayer from "@/components/videos/MuxVideoPlayer";
import { sanitizeString } from "@/src/sanity/utils/sanitizeString";
import SiteLink from "@/components/common/SiteLink";
import EmailListSignupForm from "@/components/common/EmailListSignupForm";
import FeaturedCourses from "@/components/sanity/featuredCourses";
import FeaturedGiftCourses from "@/components/sanity/featuredGiftCourses";
import FeaturedProducts from "@/components/sanity/featuredProducts";

const builder = imageUrlBuilder({ projectId, dataset });

export const urlFor = (source) => {
	return builder.image(source);
};

export const portableTextComponents = {
	block: {
		h1: ({ children }) => <Header1 className="mt-6 mb-4">{children}</Header1>,
		h2: ({ children }) => <Header2 className="mt-6 mb-3">{children}</Header2>,
		h3: ({ children }) => <Header3 className="mt-6 mb-3">{children}</Header3>,
		h4: ({ children }) => <Header4 className="my-2">{children}</Header4>,
		normal: ({ children }) => <p className="mb-4">{children}</p>,
		blockquote: ({ children }) => <blockquote>{children}</blockquote>,
		code: ({ children }) => <code>{children}</code>,
	},
	types: {
		image: ({ value }) => {
			return (
				<div
					className="flex w-full items-start aspect-auto py-0"
					style={{
						justifyContent: sanitizeString(value?.alignment) || "center",
					}}
				>
					<Image
						src={urlFor(value).url()}
						className="responsive-sanity-image"
						alt={sanitizeString(value.alt) || ""}
						width={value?.metadata?.dimensions?.width || 100}
						height={value?.metadata?.dimensions?.height || 100}
						style={{
							"--image-size": `${value?.width || 100}%`,
						}}
						loading="lazy"
					/>
				</div>
			);
		},
		muxWrapper: ({ value }) => {
			const { video, title, description, transcript } = value;
			if (!video && !video?.asset && !video?.asset?.playbackId) return null;
			``;
			return (
				<div
					className=" mx-auto py-6 responsive-sanity-video"
					style={{
						"--video-size": `${value?.width || 100}%`,
					}}
				>
					<MuxVideoPlayer
						playbackId={video?.asset?.playbackId}
						thumbnailTime={video?.asset?.thumbTime}
						title={title}
					/>
				</div>
			);
		},
		blockTwoColumns: ({ value }) => {
			const { leftColumn, rightColumn } = value;
			return (
				<div className="flex w-full items-start flex-col md:flex-row gap-x-6 gap-y-12 md:gap-y-0">
					<div className="w-full md:w-1/2">
						<PortableText
							value={leftColumn}
							components={portableTextComponents}
						/>
					</div>

					<div className="w-full md:w-1/2 ">
						<PortableText
							value={rightColumn}
							components={portableTextComponents}
						/>
					</div>
				</div>
			);
		},
		blockThreeColumns: ({ value }) => {
			const { leftColumn, centerColumn, rightColumn } = value;
			return (
				<div className="flex w-full items-start flex-col md:flex-row gap-x-6 gap-y-12 md:gap-y-0">
					<div className="w-full md:w-1/3">
						<PortableText
							value={leftColumn}
							components={portableTextComponents}
						/>
					</div>
					<div className="w-full md:w-1/3">
						<PortableText
							value={centerColumn}
							components={portableTextComponents}
						/>
					</div>
					<div className="w-full md:w-1/3 ">
						<PortableText
							value={rightColumn}
							components={portableTextComponents}
						/>
					</div>
				</div>
			);
		},
		// featuredCourses: ({ value }) => <FeaturedCourses value={value} />,
		// featuredShopifyProducts: ({ value }) => <FeaturedProducts value={value} />,
		// featuredGiftCourses: ({ value }) => <FeaturedGiftCourses value={value} />,
		emailMarketingForm: ({ value }) => (
			<div className="flex w-full border rounded-lg py-6 px-4 my-4 md:px-6">
				<EmailListSignupForm value={value} centerHeading={true} />
			</div>
		),
	},
	marks: {
		em: ({ children }) => <em>{children}</em>,
		link: ({ value, children }) => {
			const target = (value?.href || "").startsWith("http")
				? "_blank"
				: "_self";
			return (
				<SiteLink href={value?.href} target={target}>
					{children}
				</SiteLink>
			);
		},
	},
	list: {
		bullet: ({ children }) => (
			<ul className="list-disc mt-xl gap-y-2 flex flex-col pt-1 pb-4 px-6 list-inside">
				{children}
			</ul>
		),
		number: ({ children }) => (
			<ol className="mt-xl gap-y-2 flex flex-col pt-1 pb-4 px-6 list-decimal list-inside">
				{children}
			</ol>
		),
		checkmarks: ({ children }) => (
			<ol className="m-auto text-lg">{children}</ol>
		),
	},
	listItem: {
		bullet: ({ children }) => <li>{children}</li>,
		number: ({ children }) => <li>{children}</li>,
		checkmark: ({ children }) => <li>✅ {children}</li>,
	},
};
