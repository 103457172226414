import { twMerge } from "tailwind-merge";

export const Header4 = ({ children, className, ...props }) => {
	return (
		<h4
			className={twMerge(
				"font-semibold font-heading text-lg md:text-xl md:leading-5",
				className
			)}
			{...props}
		>
			{children}
		</h4>
	);
};

export default Header4;
